

import DeepCompsetAnalysisService, { DeepCompsetAnalysisServiceS } from '@/modules/deep-analysis/deep-analysis.service';
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import { $enum } from 'ts-enum-util';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import COMPARE_TO from '@/modules/deep-analysis/constants/compare-to-filter.constant';
import ProvidersService, { ProvidersServiceS } from '@/modules/providers/providers.service';

const AVAILABLE_PROVIDERS = ['all', 'booking', 'expedia'];

@Component({
    components: { CustomSelect },
})
export default class ProviderFilter extends Vue {
    @Inject(DeepCompsetAnalysisServiceS) deepCompsetAnalysisService!: DeepCompsetAnalysisService;
    @Inject(ProvidersServiceS) private providersService!: ProvidersService;

    get value() {
        return this.deepCompsetAnalysisService.provider;
    }

    set value(value: string) {
        this.deepCompsetAnalysisService.provider = value;
    }

    get items(): Item[] {
        return AVAILABLE_PROVIDERS.map((value): Item => ({
            value,
            name: this.getProviderLabel(value),
            disabled: false,
        }));
    }

    getProviderLabel(providerName: string) {
        const { data } = this.providersService;

        if (!data) {
            return providerName;
        }

        const provider = data.find(currentProvider => currentProvider.name === providerName);

        if (!provider) {
            return '';
        }

        return provider.label || provider.name || '';
    }
}
