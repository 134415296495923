

import DeepCompsetAnalysisService, { DeepCompsetAnalysisServiceS } from '@/modules/deep-analysis/deep-analysis.service';
import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import { $enum } from 'ts-enum-util';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import SHOW_BY from '@/modules/deep-analysis/constants/show-by-filter.constant';

@Component({
    components: { CustomSelect },
})
export default class ShowByFilter extends Vue {
    @Inject(DeepCompsetAnalysisServiceS) deepCompsetAnalysisService!: DeepCompsetAnalysisService;

    get value() {
        return this.deepCompsetAnalysisService.showBy;
    }

    set value(value: SHOW_BY) {
        this.deepCompsetAnalysisService.showBy = value;
    }

    get items(): Item[] {
        const daysList = [7, 30, 60, 90, 365];
        const bookDateItems = daysList.map(value => ({
            value: `book.${value}`,
            name: `Book dates ${value}`,
        }));
        const stayDateItems = daysList.map(value => ({
            value: `stay.${value}`,
            name: `Stay date ${value}`,
        }));
        return [
            ...stayDateItems,
            ...bookDateItems,
        ];
    }
}
